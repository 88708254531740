import React from 'react'
import ReactCardSlider from 'react-card-slider-component';
// import '../css/CardSlider.css';
export default function CardSlider() {
  const sliderClick = (slider)=>{
    // alert("hello world");
   
  }
  const slides = [
    {image:"assets/excel-academy/excel1.png",title:"Name",description:"Achievements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel3.png",title:"Name",description:"Achievements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel4.png",title:"Name",description:"Acheivements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel6.png",title:"Name",description:"Acheivements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel5.png",title:"Name",description:"Acheivements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel10.png",title:"Name",description:"Acheivements",clickEvent:sliderClick},
    {image:"assets/excel-academy/excel12.png",title:"Name",description:"Acheivements",clickEvent:sliderClick},
]
  return (<>
  
  <ReactCardSlider slides={slides}/>

  </>
  )
}
