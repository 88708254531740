import React from 'react'
import CoursesCard from '../custom components/CoursesCard'
import '../css/Courses.css';
import '../css/registration.css'
import Registration from './Registration';
export default function Courses() {
  return (
    <div className="coursecontainer p-0 m-0">
    <div className="boxcourse p-2 d-flex align-items-center justify-content-center">
      <div>
        <h1 className="fs-1 text-white fw-bold text-center">Courses</h1>
      </div>
    </div>
    {/* <div class=" h-100 bg-primary"> */}
{/* <div  class=" fs-2  text-dark bg-white shadow-lg p-4 "> All Courses</div> */}
{/* <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 /><CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 />
 <CoursesCard 
 course=" bussiness management" 
 professer="aditya Menon" 
 time="10 weeks" 
 levels="beginer" 
 lessons=" 11 lessons"  
 students=" 256 "
 content="Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 "
 /> */}
 {/* <div class=" col-md-12 bg-primary col-sm-12 col-lg-8 d-flex align-items-center justify-content-center p-2"> */}
      <div class="row ">
        {/* <div class=" col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-start p-2">
        <div class="card  border border-0  bg-white rounded-4 shadow-lg m-2" data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/dumy.png" class="img-reg m-2 "  height=" 80" width="80"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title text-primary "> computer science </h4>
      <p class="card-text  fs-6  text-black "> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
       Lorem Ipsum has been the industry's standard</p>
    <a class="btn btn-outline-primary btn-md text-decoration-none  "href="/"> More Details </a>      
    </div>
  </div>  


        </div> */}
        <div class=" test  col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card  card-bord border border-2  bg-white rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> Civil Services</h4>
      <p class="card-text  fs-6   ">Civil servants undertake a wide variety of activities, providing frontline support to people using public services. We help you get through IAS, IPS, IFS and IRS</p>     
      <p>For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p>
    </div>
  </div>  
        </div>
        <div class=" test  col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2  bg-white rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> Foundation Course</h4>
      <p class="card-text  fs-6  ">  foundation course is a course that you do at some colleges and universities in order to prepare yourself for a longer or more advanced course.</p>     
      <p>For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p>
    </div>
  </div>  
        </div>
        <div class=" test  col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2   rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> KTET</h4>
      <p class="card-text  fs-6   "> The primary purpose of KTET is to select teachers for all classes and [for Language Teachers , Specialist Teachers (Art & Craft) and Physical Education teachers].</p>     
      <p>For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p>
    </div>
  </div>  
        </div>
        <div class=" test   col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2   rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> CUET</h4>
      <p class="card-text  fs-6   ">CUET is a national-level exam for admission to undergraduate, postgraduate, and research programmes courses. </p>     
      <p>For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p> 
    </div>
  </div>  
        </div>
        
        
        {/* <div class="test bg-warning col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center  p-2">
        <div class="card  border border-0 bg-white rounded-4 shadow-lg m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/med-engg2.png" class="img-reg m-2" height="100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title text-white "> Medical & engineering </h4>
      <p class="card-text  fs-6  text-white "> We prepare you to excel in your medical or engineering entrance exams which will help you get into prestigious institutions</p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/">More Details </a> 
    </div>
  </div>  


        </div> */}
        <div class="test  col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord  border border-0  bg-white rounded-4 shadow-sm m-2 w-75  " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/job-3.png" class="img-reg m-2" height=" 100" width="100"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> Gate 24 </h4>
      {/* <h5>Carrier guidance series</h5> */}
      <p class="card-body  "> 
      General aptitude{<br/>}
      Reasoning & Comprehension{<br/>}
      Philosophy & Sociology{<br/>}
      Psycology & English</p>     
      <p class="card-mal">For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p>
    </div>
  </div>  


        </div>
        <div class="test  col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord  border border-0  bg-white rounded-4 shadow-sm m-2 w-75  " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/job-3.png" class="img-reg m-2" height=" 100" width="100"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title   "> Defence Service </h4>
      <p class="card-mala"> ഇന്ത്യൻ ആർമിയിലോ, വ്യോമസേനയിലോ,  നാവികസേനയിലോ ഓഫീസറാകുക എന്നതാണ്  ആഗ്രഹമെങ്കിൽ ഈ ക്ലാസ്സിൽ പങ്കെടുത്ത്  സ്വപ്നങ്ങൾ യാഥാർത്ഥ്യമാകട്ടെയെന്ന് ആശംസിക്കുന്നു.</p>     
      <p class="card-mal">For More Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM</p>
    </div>
  </div>  


        </div>
      </div>
    {/* </div> */}
    {/* </div> */}
  
    </div>
    
  )
}





  
   

