import React from 'react';
import CarouselComponent from'../custom components/CarouselComponent';
import CustomCard from '../custom components/CustomCard'
import CoursesCard from '../custom components/CoursesCard';
import Courses from './Courses';
import Registration from './Registration';
import'../css/Home.css';
import CardSlider,{Carousalcard} from './CardSlider';
export default function Home() {
  return (
    <div class="p-1">
    <CarouselComponent/>
    <div  class="  p-2 mt-5" id="containerinfo">    
         <div class="p-3 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row m-0 p-0">
        <div class=" col-sm-12 col-md-12 col-lg-6   d-flex  justify-content-center align-items-center" >
          
          <img src="/assets/what-we-stand-for.png" class=" img img-fluid w-100  h-100" alt="test image" id="aboutimg" />   
                   
        </div>
        <div class="what col-sm-12 col-md-12 col-lg-6 p-3  d-flex  justify-content-center align-items-center" >
          <div class="me-3  ">       
          <p  class="fs-1 fw-bold  ">
          What We Stand For
         </p>
         <p  class=" fs-5   ">
         It is no secret that poverty is a major problem in India. The middle class seems to be disappearing and the gap between the upper class and the lower class is widening. Our mission is to break the cycle of poverty through education. We empower young children and their family for a better living.
         </p>
         <p  class=" fs-5   ">
         We are committed to helping students see possibilities beyond what they experience every day by empowering young students to achieve their aspirations of  civil service exams and other job oriented competitive exams. Excel Academy marks its unique mission in identifying poor but intelligent students and equipping them for higher education with full or partial scholarships.
         </p>
         <a class="btn btn-outline btn-lg text-decoration-none  "href="/">Join Us </a> 
         </div>
        </div>
      </div>
    </div>    
  </div>
    <Registration/>
    <div className="boxabout p-2 d-flex align-items-center justify-content-center">
    <div>
      <h1 className="fs-1 text-white fw-bold text-center">Results & Acheivements</h1>
    </div>
  </div>
    <div class="d-flex justify-content-center w-100 mt-3">
      <CardSlider/>
    </div>
    
    
   
  {/* <div  class="p-2">
  <div class="  row " id=" cards-container">
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>

  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
   </div>
</div> */}
</div>
  )
}
