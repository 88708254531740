 export const Array=[
   '/assets/excel-academy/dbclc-2.png',
   '/assets/excel-academy/excel1.png',
   '/assets/excel-academy/excel2.png',
   '/assets/excel-academy/excel3.png',
   '/assets/excel-academy/excel4.png',
   '/assets/excel-academy/excel5.png',
   '/assets/excel-academy/excel6.png',
   '/assets/excel-academy/excel7.png',
   '/assets/excel-academy/excel8.png',



];