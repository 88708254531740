import React from 'react'
// import '../css/contact.css'
function Contactcard(props) {
  return (
            <div class="card shadow shadow-lg w-75   ">
                  <div class="card-body card-contact  rounded-3 p-4">
                  {props.icon} 
                   {/* <i class="fa fa-map-marker-alt fa-2x p-1 m-2 text-white"></i> */}
                    <p class=" fs-4">
                      {props.title}
                    </p>
                    <p class="fw-5 ">
                    {props.content} 
                    </p>
                  </div>
                </div>       
      );
    }
    
    export default  Contactcard;
    
  

