

import React from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';


function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg sticky-top shadow-lg" id="navbar">
      <div class="container-fluid">
        <img src="/assets/logos/excel-logo.png" alt="university" width="150" height="50" className="" />

        <span  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
          <span class="navbar-toggler-fa-sharp fa-solid fa-bars  color-dark"></span>
        </span>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto me-2">
            <li class="nav-item active">
              <Link class="nav-link active fs-6 px-3" to="/">
                <p class="nav-link p-0 m-0 fs-6">Home</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/about">
                <p class="nav-link p-0 m-0 fs-6">About</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/courses">
                <p class="nav-link p-0 m-0 fs-6">Courses</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/gallery">
                <p class="nav-link p-0 m-0 fs-6">Gallery</p>
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/resource">
                <p class="nav-link p-0 m-0 fs-6">Resource</p>
              </Link>
            </li> */}
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/news&events">
                <p class="nav-link p-0 m-0 fs-6">News & Events</p>
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/contact">
                <p class="nav-link p-0 m-0 fs-6">Contact</p>
              </Link>
            </li>
           
           {/* <li class="nav-item">
              <a class="nav-link active fs-6 px-3" href="http://www.minoritywelfare.kerala.gov.in/">
                <p class="nav-link p-0 m-0 fs-6">CCMY</p>
              </a>
            </li> */}
            <li class="nav-item">
              <Link class="nav-link active fs-6 px-3" to="/ccmy">
                <p class="nav-link p-0 m-0 fs-6">CCMY</p>
              </Link>
            </li>
            {/* Add more items here if needed */}
          </ul>
        </div>
      </div>
    </nav>
  );
}


export default Navbar;















// import react, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import'../css/navbar.css';
//  function NavbarTop() {
//   const[click,setClick]=useState(false);
//   const[button,setButton]=useState(true);
//   const[navbars,setNavbar]=useState(false);

//   return (
    
//    // fixed-top       for making scrollbar fixed at the top
//    // sticky-top       for sticky component at tthe top
//       // <nav class={ navbars ?'navbar nav-active sticky-top navbar-expand-lg' :'navbar navbar-img nav-inactive  sticky-top navbar-expand-lg bg-warning p-0'}>
//       <nav class=' navbar-img  navbar-expand-lg w-100 shadow-lg p-0 m-0 justify-content-center align-items-center' >
//        <div class="row p-0 m-0 w-100">
//        <div class="col-lg-12 logo-container d-flex w-100 align-items-center  justify-content-between ">
//           <div class=" col-lg-6 col-md-12 col-sm-6 d-flex w-100  align-items-center  justify-content-between  ">
//             <div class=" d-flex align-items-center">
//               <Link class="navbar-brand  fs-2 " to="/">
//                 <img
//                   src="/assets/logos/excel-logo.png"
//                   alt="university"
//                   width="180"
//                   height="100"
//                 />
//               </Link>
//            </div>
//             <span class=" ms-5  ">
//               <span
//                 class="navbar-toggler mr-auto "
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target="#navbarNav"
//                 aria-controls="navbarNav"
//                 aria-expanded="true"
//                 aria-label="Toggle navigation">
//                   <i class="  fa-solid fa-bars text-white "></i>
//                 {/* <span class="navbar-toggler-icon rounded"></span> */}
//               </span>
//             </span>
//           </div>
//         </div>
//    </div>
//   </nav>

//   )
//   }
// export default NavbarTop;
//  export function NavbarBottom() {
//   const[click,setClick]=useState(false);
//   const[button,setButton]=useState(true);
//   const[navbars,setNavbar]=useState(false);

//   return (
    
//    // fixed-top       for making scrollbar fixed at the top
//    // sticky-top       for sticky component at tthe top
//       <nav class=' navbar-img sticky-top navbar-expand-lg p-0' >
//        <div class="row p-0 m-0 w-100">
      
        
//      <div class=" col-lg-12 collapse navbar-collapse sticky-top p-0 m-0 " id="navbarNav">
//       <ul class="navbar-nav ms-auto ">
//         <li class="nav-item active   ">
//         <Link  class="nav-link active   " to="/"><p class="link-text p-0 m-0 px-4 "> Home</p></Link> 
//         {/* <a href="/" class="nav-link active  fs-5   ">Home</a> */}
//         </li>       
//         <li class="nav-item ">
//         <Link class="nav-link active " to="/about"><p class="link-text p-0 m-0 px-4">About</p></Link> 
//         {/* <a href="/" class="nav-link active  fs-5   ">About</a> */}
//         </li>
//         <li class="nav-item ">
//           <Link class="nav-link active  " to="/contact"><p class="link-text p-0 m-0 px-4">Contact</p></Link> 
//           {/* <a href="/" class="nav-link active   fs-5 ">Contact</a> */}
//         </li>
//         {/* <li class="nav-item ">
//           <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link> 
//           <a href="/" class="nav-link active   fs-5">Faculty </a>
//         </li>
//         <li class="nav-item ">
//           <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link> 
//           <a href="/" class="nav-link active  fs-5  ">Student Material</a>
//         </li>
       
//         <li class="nav-item dropdown">
//           <a class="nav-link  active fs-5  dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//             Coach Excel Educations
//           </a>
//           <ul class="dropdown-menu bg-light w-100 " aria-labelledby="navbarDropdown">
//             <li><a class="dropdown-item fs-6 "  href="/">Education</a></li>
//             <li><a class="dropdown-item fs-6 " href="/">Promotion</a></li>
//             <li><hr class="dropdown-divider active fs-5 fw-bold"/></li>
//             <li><a class="dropdown-item fs-6 " href="/">Job</a></li>
//           </ul>
//         </li>
//         */}
//       </ul>   
//     </div>
   
//     </div>
   
//   </nav>

//   )
//   }