
// import './App.css';
import Home from "./components/view/Home";
import NavbarTop,{NavbarBottom} from "./components/view/Navbar";
 import Footer from "./components/view/Footer";
 import Contact from "./components/view/Contact"; 
 import Contactcard from "./components/custom components/Contactcard";
 import Gallery from "./components/view/Gallery";
 import Courses from "./components/view/Courses";
 import NewsEvents from "./components/view/NewsEvents";
 import Resourse from "./components/view/Resource";
 import CCMY from "./components/view/CCMY";
 import ScrollToTopButton from "./components/view/ScrollToTopButton";
 import Registration from "./components/view/Registration";
 import CustomCard from "./components/custom components/CustomCard";
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom'; 
import About from "./components/view/About";
import Navbar from "./components/view/Navbar";
import CardSlider from "./components/view/CardSlider";


function App() {
  return (
    <Router>
<div class="App bg-light">
{/* <NavbarTop/>
<NavbarBottom/> */}
<Navbar/>
<ScrollToTopButton />
<div>
   <Routes>
<Route path="/" element={<Home />}/>
<Route path="/about" element={<About />}/> 
<Route path="/contact" element={<Contact/>}/> 
<Route path="/courses" element={<Courses/>}/> 
<Route path="/gallery" element={<Gallery/>}/> 
<Route path="/news&events" element={<NewsEvents/>}/> 
<Route path="/resource" element={<Resourse/>}/> 
<Route path="/ccmy" element={<CCMY/>}/>
</Routes>  

{/* <CoursesCard/> */}

{/* <Card/> */}
{/* <CardSlider/> */}
</div>

<Footer/>



</div>
</Router> 
    
  );
}

export default App;
