import React from 'react';
import '../css/aboutUs.css';
import Navbar from './Navbar';

export default function About() {
  return (
    <div className="aboutcontainer p-0">
      <div className="boxabout p-2 d-flex align-items-center justify-content-center">
        <div>
          <h1 className="fs-1 text-white fw-bold text-center">About Us</h1>
        </div>
      </div>
      <div className="p-2 my-5 " id="about1">     
          <div className="row m-0 p-0">
            <div className="demo col-md-6 col-sm-12 col-lg-6  d-flex justify-content-center align-items-center ">
              <div className="me-3 text p-3">
                <p className="fs-1 fw-bold ">About Excel Academy</p>
                <p className="fs-5 ">
                  Excel Academy is a long cherished educational institution envisaged by the Archdiocese of Thrissur established by far-sighted visionary Mar Andrews Thazhath, Archbishop of Thrissur. This is a training center for excellence intended to give right orientation and value-based training for talented children to reach high profiles in their life and career.
                </p>
              </div>
              </div>
              <div className=" col-md-6 col-sm-12 col-lg-3 py-1  d-flex justify-content-center align-items-end ">
            <img src="/assets/excel-academy/excel8.png" className="img-about" alt="test image" id="aboutimg" />
            </div>
            <div className=" col-md-6 col-sm-12 col-lg-3 py-1   d-flex justify-content-center align-items-center ">
                 <img src="/assets/excel-academy/excel5.png" className="img-about2" alt="test image" id="aboutimg" />
              </div>
          </div>     
        </div>
        
        <div className='row m-0 p-0'>
        <div className="col-md-6 col-sm-12 col-lg-3 py-3  d-flex justify-content-center align-items-center">
        <img src="/assets/images/dbclc4.png" className="img-about" alt="test image" id="aboutimg" />
        </div>
        <div className="col-md-6 col-sm-12 col-lg-3 py-3  d-flex justify-content-center align-items-end ">
            <img src="/assets/images/dbclc3.png" className="img-about2" alt="test image" id="aboutimg" />
            </div>
            <div className="demo col-md-6 col-sm-6 col-lg-6  p-3 d-flex justify-content-center align-items-center">
          <div className="me-3">
            <p className="fs-1 fw-bold ">Our Mission</p>
            <p className="fs-5 ">Excel Academy, as an institute under the management of the Archdiocese of Thrissur, is always in contact with urban and rural parishes in and around the diocese. The parish priest can suggest the deserving candidates who need financial help for their education. Full and partial scholarships are provided for the studies as their tuition fees. Today more than seven hundred deserving students are studying with scholarships. The students or parents would take the pledge that they would help a poor student when they are able to do so in the future.</p>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0">
        <div className="  col-sm-12 col-md-12 col-lg-7 p-5 ">
          <div className="  desti  bg-light justify-content-start ">
            <p className="  fs-4 fw-bold p-0  "><h2>How You Can Help</h2></p>
            <p className=" fs-5  p-0 ">You can financially help a student by sponsoring them with full or partial contributions. Each penny you spend is a contribution to building a better society through education.</p>
            <div>
              <a className="btncad btn-outline btn-sm text-decoration-none p-2 m-1" href="/">Sponsor a child</a>
            </div>
          </div>
        </div>
        <div className="  col-sm-12 col-md-12 col-lg-5 p-3 d-flex align-items-center justify-content-center">
        
          <img src="/assets/what-we-stand-for.png" className="img img-fluid w-80 h-75" alt="test image" id="aboutimg" />
        </div>
      </div>
      
      <div className="p-2 bg-white" id="about3">
        <div className="p-3 my-5">
          <div className="row m-0 p-0 ">
            <div className="col-md-6 col-sm-12 col-lg-6  d-flex  align-items-center">
              <img src="/assets/images/slide-2.jpg" className="img w-100 h-80 img-about" alt="test image" id="aboutimg" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6  d-flex  align-items-center ">
                <div>
                <p className="fs-4 text-dark">Break Poverty</p>
                <p className="hidden fs-6 text-dark">We identify capable students in poor backgrounds from every parish in Thrissur and help them rise up</p>
              </div>
              </div>
   
            </div>
            <div className="row m-0 p-0 ">
            
              <div className="col-sm-12 col-md-12 col-lg-6 d-flex  align-items-center justify-content-center">
                <div>
                <p className="fs-4 text-dark">Better Society</p>
                <p className="hidden fs-6 text-dark">Educated students are the building blocks of our society, and with your help, we can make it happen</p>
              </div>
              </div>
              <div className="image content col-md-6 col-sm-12 col-lg-6  d-flex  align-items-center">
              <img src="/assets/images/slide-1.jpg" className="img w-100 h-80  img-about" alt="test image" id="aboutimg" />
              </div>
              </div> 
              
            <div className='row m-0 p-0'>
            <div className="  image content col-md-6 col-sm-12 col-lg-6  d-flex  align-items-center">
              <img src="/assets/images/safe-future.png" className="img w-100 h-80 img-about" alt="test image" id="aboutimg" />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6  d-flex  align-items-center justify-content-center">
                <div>
                <p className="fs-4 text-dark">Secure Future</p>
                <p className="hidden fs-6 text-dark">We can break the cycle of poverty through education and secure the future of our society</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
  );
}
