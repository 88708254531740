import React from 'react'
import'../css/footer.css'
 function Footer() {
  return (
    <div class=" px-1 m-0" id="footer"> 
    <div>
    <img src="/assets/logos/excel-logo.png" alt="university" width="200" height="85" class=" m-3 "/>
    <div class=" row my-7 mx-4 m-0 p-0 " >
  <div class="d-flex justify-content-start col-md-4 col-sm-12 col-lg-4  p-2 h-75 ">
<div class="">
<h3 class="text-light "> Excel Academy </h3>
  <p class="text-light fs-6 " >
  Our mission is to break the cycle of poverty through education.
   We empower young children and their family for a better living.
  We are committed to helping students see possibilities beyond what they experience every day by empowering young students to achieve their aspirations of
   civil service exams and other job oriented competitive exams. </p>
  <p class="text-light fs-6"> <i class="  fa-solid fa-location-dot text-white "></i>
  D.B.C.L.C., Archbishop's House Compound, East Fort, <br/>Thrissur -680005
    </p>   
    {/* <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
    Civil Service Campus, Avinissery, Ollur, Thrissur
    </p>  */}
    {/* <p class="text-dark fs-6"> <i class="  fa-solid fa-clock text-dark "></i>
    Monday - Saturday at 9 AM - 6 PM
    </p>  */}
     
</div>
  </div>
  <div class="d-flex justify-content-start  col-md-6  col-sm-12 col-lg-3 p-2  ">
  <div class="">
  <h3 class="text-light "> Contacts</h3>
  <p class="text-light fs-6 "> <i class="fa-solid fa-location-dot text-white"></i>
  D.B.C.L.C., Archbishop's House Compound, East Fort, Thrissur -680005
  </p>
  {/* <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
  Civil Service Campus, Avinissery, Ollur, Thrissur
  </p> */}
  <p class="text-light  fs-6"><i class="fa-solid fa-calendar-days text-white mx-1 "></i> Monday-Saturday, <br/> 9:30 AM - 5:00 PM </p>
  <p class="text-light  fs-6"><i class="fa-solid fa-envelope text-white mx-1"></i> excelacademytcr@gmail.com </p> 
  <p class="text-light fs-6"><i class="fa-solid fa-phone text-white mx-1"></i>  +91 9744941585 </p>
</div> 
  </div>
  <div class=" d-flex justify-content-start col-md-4 col-sm-12 col-lg-4  p-2 ">
  <div class=" px-5 ">
  <h3 class="text-light "> Page links..</h3>
  {/* <div><a class="  fs-6 text-dark text-decoration-none " href="#Services"> ..Courses </a></div> */}
  {/* <div><a class=" fs-6 text-dark text-decoration-none" href="#team"> ..Team </a></div> */}
  <div><a class="fs-6 text-light text-decoration-none " href="/"> ..Home </a></div>
  <div><a class="fs-6 text-light text-decoration-none " href="/about"> ..About </a></div>
  <div><a class="fs-6 text-light text-decoration-none " href="/courses"> ..Courses</a> </div>
  <div><a class="fs-6 text-light text-decoration-none " href="/gallery"> ..Gallery </a></div>
  <div><a class="fs-6 text-light text-decoration-none " href="/resources"> ..Resources </a></div>
  <div><a class="fs-6 text-light text-decoration-none " href="/news&events"> ..News & Events </a></div>
  <div><a class="fs-6 text-light text-decoration-none " href="/contact"> ..Contact</a> </div>
  <div><a class="fs-6 text-light text-decoration-none " href="/ccmy"> ..CCMY </a></div>
</div>

  </div>
 
 
</div>
<div class=" row bg-black p-0 m-0 ">
<div class="col-sm-12 col-md-4 col-lg-7  d-flex align-items-center justify-content-center ">     
    <div class=" fs-6 text-white text-center">© Copyright 2023  Designed By Apex Data Technologies All Rights Reserved.</div>
    </div>
    <div class=" col-sm-12 col-md-4 col-lg-5  d-flex align-items-center justify-content-center " > 
    <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-facebook  fa-2x "></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-twitter  fa-2x"></i>  </a> */}
    <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-instagram  fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-linkedin fa-2x"></i>  </a> */}
    <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-youtube fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-pinterest fa-2x"></i>  </a> */}
    <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-whatsapp  fa-2x"></i>  </a>
    {/* <a class=" m-2 text-decoration-none px-1" href="/"> <i class="  fa-brands fa-telegram fa-2x"></i>  </a> */}
   </div>
 
  </div>
      </div>  
           
    </div>
  )
}
export default Footer;