import React from 'react'
import '../css/Resource.css';
export default function Resource() {
  return (
    <div className="resourcecontainer p-0 vh-100">
    <div className="boxresourse p-2 d-flex align-items-center justify-content-center">
      <div>
        <h1 className="fs-1 text-white fw-bold text-center">Resources</h1>
      </div>
    </div>
    </div> 
  )
}
