import React from 'react'
import {Array} from "./Array";
import '../css/Gallery.css';

export default function Gallery() {
  return (
   
    <div className="resourcecontainer p-0">
    <div className="boxgallery w-100 p-2 d-flex align-items-center justify-content-center">

    
    <h1 className="fs-1 text-white fw-bold text-center">Gallery</h1>
    
    </div>
      <div class="header">
         {Array.map((item,index)=>(
            <img src={item} 
             key={index }class="p-1 border border-3 border-black m-1" 
             alt="sorry no image" 
              height={'280'} 
              width={'397'}/>
         )
         )
         }
    </div>
    </div>
    
  )
}
