import React from 'react'
import '../css/CCMY.css'
import log from "../assets/images/pscimg.jpg";
export default function CCMY() {
  return (
   
    <div className="CCMYcontainer p-0">
    <div className="boxCCMY p-2 d-flex align-items-center justify-content-center">
      <div>
        <h1 className="fs-1 text-white fw-bold text-center">CCMY</h1>
      </div>
    </div>
    
        <div class="card justify-content-center pt-0 mt-0 h-100 w-100"  >
  <img src={log} class="" alt="imgg"/>
  {/* <div class="card-body bg-secondary">
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
  </div> */}
</div>
</div>


  )
}
