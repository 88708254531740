import React from 'react'
import '../css/contact.css'
import Contactcard from '../custom components/Contactcard';
function Contact() {

  return (
    <div class="contactcontainer p-0">
      <div class="boxcontact p-2 d-flex align-items-center justify-content-center">
        <div>
          <h1 class="fs-1 text-white fw-bold text-center">Contact Us</h1>
        </div>
      </div>

    <div class="p-3   h-100 " id="contact">
      <div class="main row w-100 m-0 p-0 d-flex justify-content-center align-items-center">
        <div class="contact-cont col-md-12 col-sm-12 col-lg-4  p-4 d-flex justify-content-center align-items-center">
          
          <Contactcard
          icon= { <i class="  fa-solid fa-location-dot fa-2x p-1 m-2  "></i>}
          content="D.B.C.L.C., Archbishop's House Compound East Fort, Thrissur -680005."
         title="Address"        
          />
          
          
          
                
        </div>
        <div class="contact-cont col-md-12 col-sm-12 col-lg-4  p-4 d-flex justify-content-center align-items-center">
                <Contactcard
                icon={<i class="fa-solid fa-envelope fa-2x p-1 m-2  "></i>}
                content="excelacademytcr@gmail.com"
                title="Email Address"


                />
               
              </div>
              <div class="contact-cont  col-md-12 col-sm-12 col-lg-4   p-4 d-flex justify-content-center align-items-center">
                <Contactcard
               icon={<i class="fa-solid fa-phone fa-2x p-1 m-2   "></i>}
               content="+91 9744941585"
               title="Phone Number"
                />
              
            </div>
      </div>
      </div>

      <div class="form row contact g-5 m-0 p-0 d-flex justify-content-center align-items-center mx-2 my-5">

        <div class=" col-md-10 col-sm-12 col-lg-10 p-3 ">
          <div class=" master ms-1 my-4  text-center ">
            <p class=" fs-1 "> Contact Us </p>
          </div>
          
            <form class=" row  m-0 p-0 " role="form" action="https://formsubmit.co/excelacademytcr@gmail.com" method="POST" onsubmit="true" >
              <div class=" form col-sm-12 col-lg-6 p-2 ">
                 <input type="text" class="form-control  border-black rounded-5 cont text-center" name="name" placeholder='Name' required />
              </div>
              <div class="col-sm-6 col-md-12 col-lg-6 p-2">
                <input type="text" class="form-control border-black rounded-5 cont text-center" name="name" placeholder='Phone' required />
              </div>
              
              <div class="col-md-12 col-lg-6 p-2 ">
                <input type="email" class="form-control border-black rounded-5 cont text-center" name="email" placeholder='E-mail' required />
              </div>
              <div class="col-md-12 col-lg-6 p-2">
                <input type="text" class="form-control border-black rounded-5 cont  text-center" name="subject" placeholder='subject' required />
              </div>
              <div class="col-md-12  ">
                <textarea class="form-control border-black rounded-5 text-center" name="message" placeholder='Message' rows="8"></textarea>
              </div>
              <input type="hidden" name="_next" value="https://excelacademy.dbclc.com/contact" />
              <div class="col-12  d-flex justify-content-center">
                <button class="btnform btn-outline btn-lg my-3 d-grid gap-2" type="submit" >Send Message</button>
              </div>
            </form>
      
        </div>

      </div>
    </div>
    
  )
}
export default Contact;
