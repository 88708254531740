import React from 'react';
import '../css/NewsEvents.css';
import logs from "../assets/images/defence.jpeg";
import logss from "../assets/images/gate.jpeg";

export default function NewsEvents() {
  return (
    <div class="newscontainer p-0">
      <div class="boxnews w-100 p-2 d-flex align-items-center justify-content-center">
        <div>
          <h1 class="fs-1 text-white fw-bold text-center">News & Events</h1>
        </div>
      </div>

      <div class="events row p-0 m-0 mb-3">
        <div class="pics col-lg-4 col-md-12">
          <img src={logs} alt="img" className="img-fluid" />
        </div>
        <div class=" px-auto col-lg-8 col-md-12 mb-5">
          <h5>Career Guidance on "DEFENCE SERVICE"</h5>
          <p>
          ഇന്ത്യൻ ആർമിയിലോ, വ്യോമസേനയിലോ,  നാവികസേനയിലോ ഓഫീസറാകുക എന്നതാണ് നിങ്ങളുടെ ആഗ്രഹമെങ്കിൽ ഈ ക്ലാസ്സിൽ പങ്കെടുത്ത്, ശ്രദ്ധയോടെ ഉൾക്കൊണ്ട്, ഉത്സാഹത്തോടെ പ്രവർത്തിക്കുക; നിങ്ങളുടെ സ്വപ്നങ്ങൾ യാഥാർത്ഥ്യമാകട്ടെയെന്ന് ആശംസിക്കുന്നു.
 ക്ലാസ്സിൽ പങ്കെടുക്കാനായി ഒക്ടോബർ 13 വെള്ളിയാഴ്ച 5 pm ന് മുൻപ് താഴെയുള്ള ഗൂഗിൾ ഫോം ലിങ്കിൽ  രജിസ്റ്റർ ചെയ്യുക.
<a href='https://forms.gle/Unchb4hJxPkgtfMz9'>https://forms.gle/Unchb4hJxPkgtfMz9</a>{<br/>}{<br/>}
        
        കൂടുതൽ വിവരങ്ങൾക്ക്{<br/>}
Excel Academy, D.B.C.L.C., {<br/>}
Archbishop's House Compound
East Fort, Thrissur - 05{<br/>}
Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM
</p>
        </div>
      </div>
      <div class="events row p-0 m-0 mb-3">
        <div class="pics col-lg-4 col-md-12">
          <img src={logss} alt="img" className="img-fluid" />
        </div>
        <div class=" px-auto col-lg-8 col-md-12 mb-5">
          <h5>ഗേറ്റ് 2024 CRASH COURSE</h5>
          <p>
          അഖിലേന്ത്യ തലത്തിൽ IIT, IISc, NIT, മുതലായ സ്ഥാപനങ്ങളിൽ മാസ്റ്റേഴ്സ് പഠിക്കുവാൻ ആഗ്രഹിക്കുന്നവർക്ക് എക്സൽ അക്കാദമി നടത്തുന്ന ക്രാഷ് കോഴ്സിൽ അഡ്മിഷൻ എടുക്കാവുന്നതാണ്. ജനറൽ ആപ്റ്റിട്യൂട്, റീസണിങ് -  കോംപ്രിഹൻഷൻ പേപ്പറുകൾക്ക് പുറമേ, എക്കണോമിക്സ്, ഇംഗ്ലീഷ്, ഫിലോസഫി, സോഷ്യോളജി, സൈക്കോളജി വിഷയങ്ങളിൽ ക്ലാസുകൾ ഉണ്ടായിരിക്കും. അഡ്മിഷൻ എൻക്വയറിക്കായി താഴെയുള്ള Google form link ഉപയോഗിക്കാവുന്നതാണ്.
<a href='https://forms.gle/55r3JQ5qUf95tpCbA'>https://forms.gle/55r3JQ5qUf95tpCbA</a>{<br/>}{<br/>}
        
        കൂടുതൽ വിവരങ്ങൾക്ക്{<br/>}
Excel Academy, D.B.C.L.C., {<br/>}
Archbishop's House Compound
East Fort, Thrissur - 05{<br/>}
Contact: +91 9744941585{<br/>}
excelacademytcr@gmail.com{<br/>}
WWW.EXCELACADEMY.DBCLC.COM
</p>
        </div>
      </div>
    </div>
  );
}
