import React,{useState} from 'react'
import '../css/registration.css'
export default function Registration() {

  
  const[form,setForm]=useState({
    name: '',
    mobile: '',
    email: '',
    qualification:'',
  });
  // const handleChange = (e) => {
  //   setForm({ ...form, [e.target.name]: e.target.value });
  // }
 

  const submitButton = (e) => {
    e.preventDefault();
    console.log(form);
    resetButton()
  }
  const resetButton = () => {
    setForm({
      name: '',
      mobile: '',
      email: '',
      qualification:'',
    });
  }

  return (
    <div  class="p-0  h-100 bg-white " id="contact">
    <div class="  row   p-3  ">
    <div class=" col-md-12 col-sm-12 col-lg-8 d-flex align-items-center justify-content-center ">
      <div class="row ">
        {/* <div class=" col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-start p-2">
        <div class="card  border border-0  bg-white rounded-4 shadow-lg m-2" data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/dumy.png" class="img-reg m-2 "  height=" 80" width="80"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title text-primary "> computer science </h4>
      <p class="card-text  fs-6  text-black "> Lorem Ipsum is simply dummy text of the printing and typesetting industry.
       Lorem Ipsum has been the industry's standard</p>
    <a class="btn btn-outline-primary btn-md text-decoration-none  "href="/"> More Details </a>      
    </div>
  </div>  


        </div> */}
        <div class=" test  col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2  bg-white rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> Civil Services</h4>
      <p class="card-text  fs-6   "> These classes are tailored to get you into the most desired civil service positions in India. We help you get through IAS, IPS, IFS and IRS</p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/courses">More Details </a> 
    </div>
  </div>  
        </div>
        <div class=" test  col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2  bg-white rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> Foundation Course</h4>
      <p class="card-text  fs-6  "> These courses are typically offered at the beginning of a college or university education and serve as a basis for more advanced and specialized studies.</p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/courses">More Details </a> 
    </div>
  </div>  
        </div>
        <div class=" test  col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2   rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> KTET</h4>
      <p class="card-text  fs-6   "> The primary purpose of KTET is to ensure that candidates who want to become teachers possess the necessary knowledge and skills to teach effectively at different levels of school education.</p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/courses">More Details </a> 
    </div>
  </div>  
        </div>
        <div class=" test   col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord border border-2   rounded-4 shadow-sm m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/civil.png" class="img-reg m-2 "  height=" 100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title  "> CUET</h4>
      <p class="card-text  fs-6   ">CUET was envisioned to streamline the admission process for undergraduate programs in central universities, replacing multiple individual university entrance exams with a single, standardized test. </p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/courses">More Details </a> 
    </div>
  </div>  
        </div>
        
        
        {/* <div class="test bg-warning col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center  p-2">
        <div class="card  border border-0 bg-white rounded-4 shadow-lg m-2 w-75 " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/med-engg2.png" class="img-reg m-2" height="100" width="100" alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title text-white "> Medical & engineering </h4>
      <p class="card-text  fs-6  text-white "> We prepare you to excel in your medical or engineering entrance exams which will help you get into prestigious institutions</p>     
      <a class="btn btn-outline btn-md text-decoration-none  "href="/">More Details </a> 
    </div>
  </div>  


        </div> */}
        <div class="test  col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord  border border-0  bg-white rounded-4 shadow-sm m-2 w-75  " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/job-3.png" class="img-reg m-2" height=" 100" width="100"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title   "> Gate 24 </h4>
      <p class="card-mala "> അഖിലേന്ത്യ തലത്തിൽ IIT, IISc, NIT, മുതലായ സ്ഥാപനങ്ങളിൽ മാസ്റ്റേഴ്സ് പഠിക്കുവാൻ ആഗ്രഹിക്കുന്നവർക്ക് എക്സൽ അക്കാദമി നടത്തുന്ന ക്രാഷ് കോഴ്സിൽ അഡ്മിഷൻ എടുക്കാവുന്നതാണ്. </p>     
      <a class="btn btn-outline btn-md text-decoration-none "href="/courses">More Details</a> 
    </div>
  </div>  


        </div>
        
        <div class="test  col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center p-2">
        <div class="card card-bord  border border-0  bg-white rounded-4 shadow-sm m-2 w-75  " data-aos="zoom-in-up"  data-aos-easing="ease-out-cubic"
    data-aos-duration="1500">
    <img src="/assets/images/job-3.png" class="img-reg m-2" height=" 100" width="100"alt="test image" />
    <div class=" card-body ">
      <h4 class="card-title   "> Defence Service </h4>
      <p class="card-mala"> ഇന്ത്യൻ ആർമിയിലോ, വ്യോമസേനയിലോ,  നാവികസേനയിലോ ഓഫീസറാകുക എന്നതാണ്  ആഗ്രഹമെങ്കിൽ ഈ ക്ലാസ്സിൽ പങ്കെടുത്ത്  സ്വപ്നങ്ങൾ യാഥാർത്ഥ്യമാകട്ടെയെന്ന് ആശംസിക്കുന്നു.</p>     
      <a class="btn btn-outline btn-md text-decoration-none "href="/courses">More Details</a> 
    </div>
  </div>  
  


        </div>
        
      </div>
      

    </div>
    
    <div class=" test  col-md-12  col-sm-12 col-lg-4 d-flex align-items-center justify-content-center">
        <div class="p-3 rounded-4 bg-white shadow-sm">
        <img src="/assets/logos/excel-logo.png" alt="  university" width="80" height="20" class="img-reg-top "/>
      <div class="ms-1 "> 
    <p  class=" fs-1 text-black ">Register Here </p>
      </div>
      <form class="row g-4  p-0 m-0"  role="form"  >
<div class="col-md-12 col-lg-12">
  <input type="text" class="form-control border-black rounded-5 reg"  name="name" placeholder='Name' value={form.name} onChange={(e)=> setForm({...form,name:e.target.value})}  required/>  
</div>
<div class="col-md-12 col-lg-12">
  <input type="text" class="form-control border-black rounded-5 reg"  name="phone" placeholder='Phone' value={form.mobile} onChange={(e)=> setForm({...form,mobile:e.target.value})}  required/>  
</div>
<div class="col-md-12 col-lg-12 ">
  <input type="email" class="form-control border-black rounded-5 reg "  name="email"  placeholder='E-mail'value={form.email} onChange={(e)=> setForm({...form,email:e.target.value})}   required/>
</div>
<div class="col-md-12 col-lg-12"> 
  <input type="text" class="form-control border-black rounded-5 reg"  name="qualification"  placeholder='Qualification'value={form.qualification} onChange={(e)=> setForm({...form,qualification:e.target.value})}  required/> 
</div>
<div class="col-12 d-flex justify-content-center  ">
  <button class="btn btn-outline btn-lg my-3" type="submit" onClick={submitButton} >Register </button> 
</div>
     </form>  
        </div>  
    </div>
      

  </div> 
  </div>
  
  
  )
}
